import type { ADDON_NAMES, PLANS } from '@proton/payments';
import { getPlansMap } from '@proton/payments';
import type { Plan } from '@proton/shared/lib/interfaces';

export const cachedPlans: Plan[] = [
    {
        ID: 'cLHnXnFVE5pMZw1vECRGpgjkIrEflLx9en1VvXempQUz-Z7I9BKQu5iCt4fmsYkYE9wQkYqdnKk5aTYQ3KKE-g==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'drivelite2024' as PLANS | ADDON_NAMES,
        Title: 'Drive Lite',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 21474836480,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 2,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 100,
        },
        DefaultPricing: {
            '1': 100,
        },
        PeriodEnd: {
            '1': 1735148146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 100,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Lab5ug5wsnIedm7-pe5hB6nZ-vVDyoznnMHbCSNoyAIiuMiSSATtyyR-BBMaqIdUiNrYiVMfy1Zmh9L-q74Lng==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'drive2022' as PLANS | ADDON_NAMES,
        Title: 'Drive Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 214748364800,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 2,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        DefaultPricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'vXOiCO533JxXlqJsrhvREDwaFhGOXXV7NYjsz06VDJGSnes72blaG8hA9547xU1NLrMsDbA3lywhjPZ7oNYNcA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'mail2022' as PLANS | ADDON_NAMES,
        Title: 'Mail Plus',
        MaxDomains: 1,
        MaxAddresses: 10,
        MaxCalendars: 25,
        MaxSpace: 16106127360,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        DefaultPricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'cX2Ji0VckbvBzZTf87sxq0_-n10qjuIiAy62NJb0Wz8-sdTbwzdRFrFrD17lk93Knk6sCfpI353FpPA7q0GQTQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'pass2023' as PLANS | ADDON_NAMES,
        Title: 'Pass Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 499,
            '12': 3588,
            '24': 7176,
        },
        DefaultPricing: {
            '1': 499,
            '12': 3588,
            '24': 7176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Oq5v73PN6q3DjnWTp4UwxDBKAfSt3cDgVVLjzrp9r9yCA8IuQJiIZKX8ZDQctXWOEFMO1ROxffMjoqwYwg_xLg==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'passpro2024' as PLANS | ADDON_NAMES,
        Title: 'Pass Essentials',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        DefaultPricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'CZLsVy9MkpYlmTELt96UJFIcgcNQFPxqEFIkjt2sGRWF3lnZNRWuIFM-sUTzdXnx4f6zUBUNQxd6-l3CuEkvMQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'passbiz2024' as PLANS | ADDON_NAMES,
        Title: 'Pass Professional',
        MaxDomains: 1,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 699,
            '12': 7188,
            '24': 11976,
        },
        DefaultPricing: {
            '1': 699,
            '12': 7188,
            '24': 11976,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 699,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'T0cX6TLun8zhYK5a6Qkr-Kg3cEuYc8aLTpNVgPEslZYOLxGFCsxoYoDk3TQTt57c_40KR5Wn1D4pQwNeoWfWwg==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'passfamily2024' as PLANS | ADDON_NAMES,
        Title: 'Pass Family',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 16106127360,
        MaxMembers: 6,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 699,
            '12': 5988,
        },
        DefaultPricing: {
            '1': 699,
            '12': 5988,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 699,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Vk-7HUMELOxtoLNAoxS1uCv_XT2jAQOAj-t2BCVOIkr_opyC6FmmVHrvoH4N-54gWpEnQVPcpe6o7dLbV0IgBw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'mailpro2022' as PLANS | ADDON_NAMES,
        Title: 'Mail Essentials',
        MaxDomains: 3,
        MaxAddresses: 10,
        MaxCalendars: 25,
        MaxSpace: 16106127360,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 799,
            '12': 8388,
            '24': 15576,
        },
        DefaultPricing: {
            '1': 799,
            '12': 8388,
            '24': 15576,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 799,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'YXhWTAQJs4oqlZqErcsNsKUpfw6LAiG-KYCgPOH8BY1JxM3aCAbqDJhK3x0evmPTI2_2oysemGTz_w-fJsQdYw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'drivebiz2024' as PLANS | ADDON_NAMES,
        Title: 'Drive Professional',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 2,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 999,
            '12': 9588,
        },
        DefaultPricing: {
            '1': 999,
            '12': 9588,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'MYGJp20LzzFI8UabkCdSxV-InEpmDQTNT8MT_0hqLvCS2f8B48-osHgPOAJGM0bgpJA1hG8neJPue8wBraqBLA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpn2022' as PLANS | ADDON_NAMES,
        Title: 'VPN Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 999,
            '12': 7188,
            '15': 14985,
            '24': 11976,
            '30': 29970,
        },
        DefaultPricing: {
            '1': 999,
            '12': 7188,
            '15': 14985,
            '24': 11976,
            '30': 29970,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '15': 1772040946,
            '24': 1795628146,
            '30': 1811266546,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'KV8mjImpquR4tux9teokTx9bRB3GDgJYgDV2r1PCaCVD9o8InZs99CZr_q2G0qyP8QES4FZyxdO5gU1K-2Jv7Q==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpn2024' as PLANS | ADDON_NAMES,
        Title: 'VPN Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 999,
            '3': 2397,
            '6': 4194,
            '12': 7995,
            '15': 14985,
            '18': 8982,
            '24': 11976,
            '30': 29970,
        },
        DefaultPricing: {
            '1': 999,
            '3': 2397,
            '6': 4194,
            '12': 7995,
            '15': 14985,
            '18': 8982,
            '24': 11976,
            '30': 29970,
        },
        PeriodEnd: {
            '1': 1735148146,
            '3': 1740504946,
            '6': 1756139032,
            '12': 1764092146,
            '15': 1772040946,
            '18': 1779730546,
            '24': 1795628146,
            '30': 1811266546,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Uc0Z4tdf4clwVr4eDSPY0bWjH9kIzvLeQ-qudaHD-WJKY21IfFV2t73civ-hnJN7dtbGpV5eUfX5DOKE_nWWcQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'mailbiz2024' as PLANS | ADDON_NAMES,
        Title: 'Mail Professional',
        MaxDomains: 10,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 53687091200,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1099,
            '12': 11988,
        },
        DefaultPricing: {
            '1': 1099,
            '12': 11988,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1099,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'yu50U8Rf9dhPHDcG3KTX6Nx3Euupk4uskAj9V9YVCFSB3oQk8_pTGfWwRFkRPYziGL5EsEx42ZyRHdcZxpO8CA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpnpass2023' as PLANS | ADDON_NAMES,
        Title: 'VPN and Pass bundle',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 12,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1099,
            '12': 10395,
            '15': 11985,
            '24': 20790,
            '30': 17970,
        },
        DefaultPricing: {
            '1': 1099,
            '12': 10395,
            '15': 11985,
            '24': 20790,
            '30': 17970,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '15': 1772040946,
            '24': 1795628146,
            '30': 1811266546,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1099,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'tHdKCeJlgD7mv_W13BqEZeelMiptPIK6r8arzZFcQcLvBLNiogdGOGVyYOldyhzcnSzCPkvkWj-VtyDwSjIncg==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'bundle2022' as PLANS | ADDON_NAMES,
        Title: 'Proton Unlimited',
        MaxDomains: 3,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 536870912000,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1299,
            '12': 11988,
            '24': 19176,
        },
        DefaultPricing: {
            '1': 1299,
            '12': 11988,
            '24': 19176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1299,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'lVJ7pFH-QRoQAcjkhfPKt9LJnWblD-bXg41QYWUZAUGwGYkUakaAB79u94pvaKb7SqBgqCbyxoaTOP3Smgm0ww==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'bundlepro2022' as PLANS | ADDON_NAMES,
        Title: 'Proton Business',
        MaxDomains: 10,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1299,
            '12': 13188,
            '24': 23976,
        },
        DefaultPricing: {
            '1': 1299,
            '12': 13188,
            '24': 23976,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1299,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'qh_qQPwjLZU-Uzx2oXLq5Bl3eS-gjhYm7mxO5rCBzGMa7oLhNMfshPmpP9QWj7d52NOT3X_UVLujhrnHigo3-w==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'bundlepro2024' as PLANS | ADDON_NAMES,
        Title: 'Proton Business Suite',
        MaxDomains: 15,
        MaxAddresses: 20,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1499,
            '12': 15588,
        },
        DefaultPricing: {
            '1': 1499,
            '12': 15588,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 's4Suo5gVNLE9UPt320KF3QleE1Zj07AYKG1O6SJ7FUIL9_3Kr73-5pDGZLvVBPotI9po-coIP_S-faG1FcOjlA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'enterprise2022' as PLANS | ADDON_NAMES,
        Title: 'Enterprise',
        MaxDomains: 10,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        DefaultPricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1599,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'v8A0xW5bt0IXa_IIqsZxLgZ4HQaIleiiyKhbdu1OBvlBZfLxN3rdegjGH9y65Jg3u88_wpjoj30yiuUo2Ci1Sw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpnpro2023' as PLANS | ADDON_NAMES,
        Title: 'VPN Essentials',
        MaxDomains: 0,
        MaxAddresses: 2,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 2,
        MaxVPN: 20,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1798,
            '12': 16776,
            '24': 28752,
        },
        DefaultPricing: {
            '1': 1798,
            '12': 16776,
            '24': 28752,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1798,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'QTWXZRCtm3JEJq3YEbBC4fO4AAuhu-nU3v09RcMq0TP4SADobZBqKUnnEPYVUn9KIxCXbvmZS7yiEyh3RCNFUQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'duo2024' as PLANS | ADDON_NAMES,
        Title: 'Proton Duo',
        MaxDomains: 3,
        MaxAddresses: 30,
        MaxCalendars: 50,
        MaxSpace: 1099511627776,
        MaxMembers: 2,
        MaxVPN: 20,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1999,
            '12': 17988,
            '24': 28776,
        },
        DefaultPricing: {
            '1': 1999,
            '12': 17988,
            '24': 28776,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '1SDBH_1L_zUuNzXzQa6JKox3qZTINidjF-5fk4SM2uzZ7UkvA2Dz_Cv1-p-X3toVYzHMwcrVKEjRqIdotiMdFw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'family2022' as PLANS | ADDON_NAMES,
        Title: 'Proton Family',
        MaxDomains: 3,
        MaxAddresses: 90,
        MaxCalendars: 150,
        MaxSpace: 3298534883328,
        MaxMembers: 6,
        MaxVPN: 60,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 2999,
            '12': 28788,
            '24': 47976,
        },
        DefaultPricing: {
            '1': 2999,
            '12': 28788,
            '24': 47976,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 2999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '6opBd5UdUtY_RtEzWLQNN128C-nG87siCoSoDAwgyGLXDNe6fKrFE9XUqmz68E-BAf1wzwipuPU4sbt2hvOrYA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'visionary2022' as PLANS | ADDON_NAMES,
        Title: 'Visionary',
        MaxDomains: 10,
        MaxAddresses: 100,
        MaxCalendars: 150,
        MaxSpace: 6597069766656,
        MaxMembers: 6,
        MaxVPN: 60,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 3999,
            '12': 35988,
            '24': 67176,
        },
        DefaultPricing: {
            '1': 3999,
            '12': 35988,
            '24': 67176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 3999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'jUjNWfdzq7X-CMrR3LyX2y7o_D6y4FIGmsuTkfdNOI4QtPyaed-kVyFcOw9XLiRpwRDKnyKtHxhrIBW7nPVZdQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpnbiz2023' as PLANS | ADDON_NAMES,
        Title: 'VPN Professional',
        MaxDomains: 1,
        MaxAddresses: 2,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 2,
        MaxVPN: 20,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 7379,
            '12': 71964,
            '24': 129528,
        },
        DefaultPricing: {
            '1': 7379,
            '12': 71964,
            '24': 129528,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 7379,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'f1MbE4ydZBpVns9Wz8mA5x0nqqadqF0a61PZXR0CkYEnsBQyiGHvw7-Co6bJ_Wl0bKQJFBCh_ppRvup_P8VPiw==',
        ParentMetaPlanID: 'lVJ7pFH-QRoQAcjkhfPKt9LJnWblD-bXg41QYWUZAUGwGYkUakaAB79u94pvaKb7SqBgqCbyxoaTOP3Smgm0ww==',
        Type: 0,
        Name: '1domain-bundlepro2022' as PLANS | ADDON_NAMES,
        Title: '+1 Domain for Business',
        MaxDomains: 1,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 150,
            '12': 1680,
            '24': 3120,
        },
        DefaultPricing: {
            '1': 150,
            '12': 1680,
            '24': 3120,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 150,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'ktqlKw3s-JEjT2s6BDWeuHqOtqmrUPsDcztLoG6l5Ik3CPQzFgpLcRAs0u2qmtEFO3tKZMWtoAUbRsWTKNV5vw==',
        ParentMetaPlanID: 'qh_qQPwjLZU-Uzx2oXLq5Bl3eS-gjhYm7mxO5rCBzGMa7oLhNMfshPmpP9QWj7d52NOT3X_UVLujhrnHigo3-w==',
        Type: 0,
        Name: '1domain-bundlepro2024' as PLANS | ADDON_NAMES,
        Title: '+1 Domain Proton Business Suite',
        MaxDomains: 1,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 150,
            '12': 1680,
        },
        DefaultPricing: {
            '1': 150,
            '12': 1680,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 150,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'JgyK70RpusE8ci9eooA4H7pEabexo1mQoYm4TSv7Z643gHTD-taJMSrbC4qIOGAN3VGvuvKAr9Y2cU6A5X789A==',
        ParentMetaPlanID: 'lVJ7pFH-QRoQAcjkhfPKt9LJnWblD-bXg41QYWUZAUGwGYkUakaAB79u94pvaKb7SqBgqCbyxoaTOP3Smgm0ww==',
        Type: 0,
        Name: '1scribe-bundlepro2022' as PLANS | ADDON_NAMES,
        Title: '+1 Scribe Seat Proton Business',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        DefaultPricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 399,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'A67wsII4qAC7ttBVyOh_1yBrNxEKa4KKhzfbOmA9DxJ-EmwzdpCSC68QYF7V1n3jBJnkxDYrffs5_pJHPI9q_w==',
        ParentMetaPlanID: 'qh_qQPwjLZU-Uzx2oXLq5Bl3eS-gjhYm7mxO5rCBzGMa7oLhNMfshPmpP9QWj7d52NOT3X_UVLujhrnHigo3-w==',
        Type: 0,
        Name: '1scribe-bundlepro2024' as PLANS | ADDON_NAMES,
        Title: '+1 Scribe Seat Proton Business Suite',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        DefaultPricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 399,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'NEXN7M46GQlROog4NYwPp4QIeGUmDG7qv4rzp2SqlncVEs5cXPWa7qnYMOxEIP19btBTk4deiaOk5yyThZknCg==',
        ParentMetaPlanID: 's4Suo5gVNLE9UPt320KF3QleE1Zj07AYKG1O6SJ7FUIL9_3Kr73-5pDGZLvVBPotI9po-coIP_S-faG1FcOjlA==',
        Type: 0,
        Name: '1scribe-enterprise2022' as PLANS | ADDON_NAMES,
        Title: '+1 Scribe Seat Enterprise',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        DefaultPricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 399,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Qb2ngNPehlUDEcIOH6T82V8CMDaqN9S_n7V6esTo78tNeJTm3cAzEUXTtGazQgsvpC3MR8yuvOZ0eEnkbK5yfQ==',
        ParentMetaPlanID: 'Uc0Z4tdf4clwVr4eDSPY0bWjH9kIzvLeQ-qudaHD-WJKY21IfFV2t73civ-hnJN7dtbGpV5eUfX5DOKE_nWWcQ==',
        Type: 0,
        Name: '1scribe-mailbiz2024' as PLANS | ADDON_NAMES,
        Title: '+1 Scribe Seat Mail Professional',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 399,
            '12': 3588,
        },
        DefaultPricing: {
            '1': 399,
            '12': 3588,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 399,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'I1vS9wAKew6h5oqAR3Vbo7p6WosmP-AtnHcZcOpDw22pdyqG3efbMJdSRtzOBrSEBgyaoOK9Ogr3wUaB9ccjxQ==',
        ParentMetaPlanID: 'Vk-7HUMELOxtoLNAoxS1uCv_XT2jAQOAj-t2BCVOIkr_opyC6FmmVHrvoH4N-54gWpEnQVPcpe6o7dLbV0IgBw==',
        Type: 0,
        Name: '1scribe-mailpro2022' as PLANS | ADDON_NAMES,
        Title: '+1 Scribe Seat Mail Essentials',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        DefaultPricing: {
            '1': 399,
            '12': 3588,
            '24': 7176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 399,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'igpLl8Gtj7Ve_fVnsnvFp_gUne1iq2gZVj87kxaEeoW7WqFAy_f8ys1KktlqPpa0fE_yGaWzuqOKQtRqaLMs3Q==',
        ParentMetaPlanID: 'Oq5v73PN6q3DjnWTp4UwxDBKAfSt3cDgVVLjzrp9r9yCA8IuQJiIZKX8ZDQctXWOEFMO1ROxffMjoqwYwg_xLg==',
        Type: 0,
        Name: '1member-passpro2024' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        DefaultPricing: {
            '1': 499,
            '12': 4788,
            '24': 8376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'bEte7pTqqDbmna--bKxM_rxlaRux8l7-E6tZ2IsYuakn5ZSJ8t-aQapwueG3HAnTRcPfR9bCkczgZGRf4ZsioA==',
        ParentMetaPlanID: 'CZLsVy9MkpYlmTELt96UJFIcgcNQFPxqEFIkjt2sGRWF3lnZNRWuIFM-sUTzdXnx4f6zUBUNQxd6-l3CuEkvMQ==',
        Type: 0,
        Name: '1member-passbiz2024' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 699,
            '12': 7188,
            '24': 11976,
        },
        DefaultPricing: {
            '1': 699,
            '12': 7188,
            '24': 11976,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 699,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'tmkbY5-BgxYUahTwB0ngjgcWIWQpCw6Q7DVSLN5PCjiss4-kp-xLRoylmJ0mSPzgyEXDHUCa26gmgNesiG4brA==',
        ParentMetaPlanID: 'Vk-7HUMELOxtoLNAoxS1uCv_XT2jAQOAj-t2BCVOIkr_opyC6FmmVHrvoH4N-54gWpEnQVPcpe6o7dLbV0IgBw==',
        Type: 0,
        Name: '1member-mailpro2022' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 10,
        MaxCalendars: 25,
        MaxSpace: 16106127360,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 799,
            '12': 8388,
            '24': 15576,
        },
        DefaultPricing: {
            '1': 799,
            '12': 8388,
            '24': 15576,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 799,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'I5iXhAkT2kFuWSdowKPodYnpVBeKqZwoZFXTp9VDTknWpxvsqtFcyHsTwFccYnBo3mD-XsQ1KqveTyl6geQzxQ==',
        ParentMetaPlanID: 'v8A0xW5bt0IXa_IIqsZxLgZ4HQaIleiiyKhbdu1OBvlBZfLxN3rdegjGH9y65Jg3u88_wpjoj30yiuUo2Ci1Sw==',
        Type: 0,
        Name: '1member-vpnpro2023' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 899,
            '12': 8388,
            '24': 14376,
        },
        DefaultPricing: {
            '1': 899,
            '12': 8388,
            '24': 14376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 899,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Yx-DYR0GKN7lKTrGumfuHTDfzUNt9noyh-KMZP0vwmkrzXh2L9F7c9M8xx7-WVPnjTFNu8wF6xV8K83P1rMGEA==',
        ParentMetaPlanID: 'YXhWTAQJs4oqlZqErcsNsKUpfw6LAiG-KYCgPOH8BY1JxM3aCAbqDJhK3x0evmPTI2_2oysemGTz_w-fJsQdYw==',
        Type: 0,
        Name: '1member-drivebiz2024' as PLANS | ADDON_NAMES,
        Title: '+1 User Drive Professional',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 2,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 999,
            '12': 9588,
        },
        DefaultPricing: {
            '1': 999,
            '12': 9588,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'CaioC57NM0BfA3WDRAG8LApascUxftg9U2rl4nU8OrqWfQfm_xO1A03EJR7XBYwGlx81IylQrK5DHhXsPt7MtQ==',
        ParentMetaPlanID: 'Uc0Z4tdf4clwVr4eDSPY0bWjH9kIzvLeQ-qudaHD-WJKY21IfFV2t73civ-hnJN7dtbGpV5eUfX5DOKE_nWWcQ==',
        Type: 0,
        Name: '1member-mailbiz2024' as PLANS | ADDON_NAMES,
        Title: '+1 User Mail Professional',
        MaxDomains: 0,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 53687091200,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 1,
        State: 1,
        Pricing: {
            '1': 1099,
            '12': 11988,
        },
        DefaultPricing: {
            '1': 1099,
            '12': 11988,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1099,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'WErNQRoJ-NaSvs5N1NnpRHvobabt5btZXBsmPuqLd5MvTJnefHSZgOigRXjdmfklxa5TSA20uJesCu9TetKupw==',
        ParentMetaPlanID: 'jUjNWfdzq7X-CMrR3LyX2y7o_D6y4FIGmsuTkfdNOI4QtPyaed-kVyFcOw9XLiRpwRDKnyKtHxhrIBW7nPVZdQ==',
        Type: 0,
        Name: '1member-vpnbiz2023' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 1,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1199,
            '12': 11988,
            '24': 21576,
        },
        DefaultPricing: {
            '1': 1199,
            '12': 11988,
            '24': 21576,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1199,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'AbDzcisHq0YHVi-3B4-H2_tjuY_WJemx3NX3zAgJ9KMbGWx_yWV08Ms8s0dng_Qt_DvMYfxLOWL2Yw1JgFREeg==',
        ParentMetaPlanID: 'lVJ7pFH-QRoQAcjkhfPKt9LJnWblD-bXg41QYWUZAUGwGYkUakaAB79u94pvaKb7SqBgqCbyxoaTOP3Smgm0ww==',
        Type: 0,
        Name: '1member-bundlepro2022' as PLANS | ADDON_NAMES,
        Title: '+1 User for Business',
        MaxDomains: 0,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1299,
            '12': 13188,
            '24': 23976,
        },
        DefaultPricing: {
            '1': 1299,
            '12': 13188,
            '24': 23976,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1299,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'q_HAaTGgl4Q3uPTsMITYS6qRKOkqnJ5jbOSXGDc5TR-s-CZymLWQcD3j1AbOjTMEYE6dRSjWDEuJdP9N1mYaCQ==',
        ParentMetaPlanID: 'qh_qQPwjLZU-Uzx2oXLq5Bl3eS-gjhYm7mxO5rCBzGMa7oLhNMfshPmpP9QWj7d52NOT3X_UVLujhrnHigo3-w==',
        Type: 0,
        Name: '1member-bundlepro2024' as PLANS | ADDON_NAMES,
        Title: '+1 User Proton Business Suite',
        MaxDomains: 0,
        MaxAddresses: 20,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1499,
            '12': 15588,
        },
        DefaultPricing: {
            '1': 1499,
            '12': 15588,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1499,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'XYbCWMJRuiILpmb8syJD4mtPyGNe2xrVAcRNmzJRx6lCmWJ_T4r6Mlc3xKqBhnoZdvtf1VXQiNQN8idDy5BWZA==',
        ParentMetaPlanID: 's4Suo5gVNLE9UPt320KF3QleE1Zj07AYKG1O6SJ7FUIL9_3Kr73-5pDGZLvVBPotI9po-coIP_S-faG1FcOjlA==',
        Type: 0,
        Name: '1domain-enterprise2022' as PLANS | ADDON_NAMES,
        Title: '+1 Domain for Enterprise',
        MaxDomains: 1,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        DefaultPricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1599,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'SKhICvkxYLs-TqZBpvHn9feBblFYXw1LpwJ0LXHRm9lFvrYds7OjJostTsR-PpMQEyYz9QAgEcxaOS9HF7Av2g==',
        ParentMetaPlanID: 's4Suo5gVNLE9UPt320KF3QleE1Zj07AYKG1O6SJ7FUIL9_3Kr73-5pDGZLvVBPotI9po-coIP_S-faG1FcOjlA==',
        Type: 0,
        Name: '1member-enterprise2022' as PLANS | ADDON_NAMES,
        Title: '+1 User for Enterprise',
        MaxDomains: 0,
        MaxAddresses: 15,
        MaxCalendars: 25,
        MaxSpace: 1099511627776,
        MaxMembers: 1,
        MaxVPN: 10,
        MaxTier: 0,
        Services: 15,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        DefaultPricing: {
            '1': 1599,
            '12': 16788,
            '24': 31176,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1599,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'E2QUmfoZFQ6UYGPheMhcKsrQy8z3gKNN2-Kktd6AyFHoryoJPEfxZJRQObsvpyYRI5FtJvd-eF-DR2dZ6Uq-Tg==',
        ParentMetaPlanID: 'lVJ7pFH-QRoQAcjkhfPKt9LJnWblD-bXg41QYWUZAUGwGYkUakaAB79u94pvaKb7SqBgqCbyxoaTOP3Smgm0ww==',
        Type: 0,
        Name: '1ip-bundlepro2022' as PLANS | ADDON_NAMES,
        Title: '+1 IP Proton Business',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        DefaultPricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 4999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '_O4OvZH6gYw77rrYMbHMOiZ6XGR-gZbUb9KkA4C_8Gm01NmV1lNy4Jdt45Ld29Io2Z9oJQnm9WrRHwYHRSw78w==',
        ParentMetaPlanID: 'qh_qQPwjLZU-Uzx2oXLq5Bl3eS-gjhYm7mxO5rCBzGMa7oLhNMfshPmpP9QWj7d52NOT3X_UVLujhrnHigo3-w==',
        Type: 0,
        Name: '1ip-bundlepro2024' as PLANS | ADDON_NAMES,
        Title: '+1 IP Proton Business Suite',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 0,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        DefaultPricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 4999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '9-EXVm5ajoie4Fl8qMhD2qpdHkgnFppxB0DjvUhhK5fqJz1pW3fiW2ajv9qkeN73GK_QnEptA69w1RRKvkt4Kg==',
        ParentMetaPlanID: 'jUjNWfdzq7X-CMrR3LyX2y7o_D6y4FIGmsuTkfdNOI4QtPyaed-kVyFcOw9XLiRpwRDKnyKtHxhrIBW7nPVZdQ==',
        Type: 0,
        Name: '1ip-vpnbiz2023' as PLANS | ADDON_NAMES,
        Title: '+1 Ip',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 1,
        Pricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        DefaultPricing: {
            '1': 4999,
            '12': 47988,
            '24': 86376,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 4999,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'ziWi-ZOb28XR4sCGFCEpqQbd1FITVWYfTfKYUmV_wKKR3GsveN4HZCh9er5dhelYylEp-fhjBbUPDMHGU699fw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'plus' as PLANS | ADDON_NAMES,
        Title: 'ProtonMail Plus',
        MaxDomains: 1,
        MaxAddresses: 5,
        MaxCalendars: 25,
        MaxSpace: 5368709120,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 500,
            '12': 4800,
            '24': 7900,
        },
        DefaultPricing: {
            '1': 500,
            '12': 4800,
            '24': 7900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 500,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'cjGMPrkCYMsx5VTzPkfOLwbrShoj9NnLt3518AH-DQLYcvsJwwjGOkS8u3AcnX4mVSP6DX2c6Uco99USShaigQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpnbasic' as PLANS | ADDON_NAMES,
        Title: 'ProtonVPN Basic',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 25,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 2,
        MaxTier: 1,
        Services: 4,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 500,
            '12': 4800,
            '24': 7900,
        },
        DefaultPricing: {
            '1': 500,
            '12': 4800,
            '24': 7900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 500,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'j_hMLdlh76xys5eR2S3OM9vlAgYylGQBiEzDeXLw1H-huHy2jwjwVqcKAPcdd6z2cXoklLuQTegkr3gnJXCB9w==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'professional' as PLANS | ADDON_NAMES,
        Title: 'ProtonMail Professional',
        MaxDomains: 2,
        MaxAddresses: 10,
        MaxCalendars: 25,
        MaxSpace: 5368709120,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 1,
        State: 0,
        Pricing: {
            '1': 800,
            '12': 7500,
            '24': 12900,
        },
        DefaultPricing: {
            '1': 800,
            '12': 7500,
            '24': 12900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 800,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'ARy95iNxhniEgYJrRrGvagmzRdnmvxCmjArhv3oZhlevziltNm07euTTWeyGQF49RxFpMqWE_ZGDXEvGV2CEkA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'business' as PLANS | ADDON_NAMES,
        Title: 'Business',
        MaxDomains: 1,
        MaxAddresses: 5,
        MaxCalendars: 25,
        MaxSpace: 10737418240,
        MaxMembers: 2,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 1000,
            '12': 9600,
        },
        DefaultPricing: {
            '1': 1000,
            '12': 9600,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1000,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'S6oNe_lxq3GNMIMFQdAwOOk5wNYpZwGjBHFr5mTNp9aoMUaCRNsefrQt35mIg55iefE3fTq8BnyM4znqoVrAyA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'vpnplus' as PLANS | ADDON_NAMES,
        Title: 'ProtonVPN Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 25,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 4,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 1000,
            '12': 9600,
            '24': 15900,
        },
        DefaultPricing: {
            '1': 1000,
            '12': 9600,
            '24': 15900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 1000,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'm-dPNuHcP8N4xfv6iapVg2wHifktAD1A1pFDU95qo5f14Vaw8I9gEHq-3GACk6ef3O12C3piRviy_D43Wh7xxQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'visionary' as PLANS | ADDON_NAMES,
        Title: 'Visionary',
        MaxDomains: 10,
        MaxAddresses: 50,
        MaxCalendars: 25,
        MaxSpace: 21474836480,
        MaxMembers: 6,
        MaxVPN: 10,
        MaxTier: 2,
        Services: 15,
        Features: 1,
        State: 0,
        Pricing: {
            '1': 3000,
            '12': 28800,
            '24': 47900,
        },
        DefaultPricing: {
            '1': 3000,
            '12': 28800,
            '24': 47900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 3000,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'vUZGQHCgdhbDi3qBKxtnuuagOsgaa08Wpu0WLdaqVIKGI5FM7KwIrDB4IprPbhThXJ_5Pb90bkGlHM1ARMYYrQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 0,
        Name: '1gb' as PLANS | ADDON_NAMES,
        Title: '+1 GB',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 25,
        MaxSpace: 1073741824,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 100,
            '12': 900,
            '24': 1600,
        },
        DefaultPricing: {
            '1': 100,
            '12': 900,
            '24': 1600,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 100,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'BzHqSTaqcpjIY9SncE5s7FpjBrPjiGOucCyJmwA6x4nTNqlElfKvCQFr9xUa2KgQxAiHv4oQQmAkcA56s3ZiGQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 0,
        Name: '5address' as PLANS | ADDON_NAMES,
        Title: '+5 Addresses',
        MaxDomains: 0,
        MaxAddresses: 5,
        MaxCalendars: 25,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 100,
            '12': 900,
            '24': 1600,
        },
        DefaultPricing: {
            '1': 100,
            '12': 900,
            '24': 1600,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 100,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'Xz2wY0Wq9cg1LKwchjWR05vF62QUPZ3h3Znku2ramprCLWOr_5kB8mcDFxY23lf7QspHOWWflejL6kl04f-a-Q==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 0,
        Name: '1domain' as PLANS | ADDON_NAMES,
        Title: '+1 Domain',
        MaxDomains: 1,
        MaxAddresses: 0,
        MaxCalendars: 25,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 200,
            '12': 1800,
            '24': 3200,
        },
        DefaultPricing: {
            '1': 200,
            '12': 1800,
            '24': 3200,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 200,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: 'OYB-3pMQQA2Z2Qnp5s5nIvTVO2alU6h82EGLXYHn1mpbsRvE7UfyAHbt0_EilRjxhx9DCAUM9uXfM2ZUFjzPXw==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 0,
        Name: '1vpn' as PLANS | ADDON_NAMES,
        Title: '+1 VPN Connection',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 25,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 1,
        MaxTier: 0,
        Services: 4,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 200,
            '12': 1800,
            '24': 3200,
        },
        DefaultPricing: {
            '1': 200,
            '12': 1800,
            '24': 3200,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 200,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '1H8EGg3J1QpSDL6K8hGsTvwmHXdtQvnxplUMePE7Hruen5JsRXvaQ75-sXptu03f0TCO-he3ymk0uhrHx6nnGQ==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 0,
        Name: '1member' as PLANS | ADDON_NAMES,
        Title: '+1 User',
        MaxDomains: 0,
        MaxAddresses: 5,
        MaxCalendars: 25,
        MaxSpace: 5368709120,
        MaxMembers: 1,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 1,
        Features: 0,
        State: 0,
        Pricing: {
            '1': 800,
            '12': 7500,
            '24': 12900,
        },
        DefaultPricing: {
            '1': 800,
            '12': 7500,
            '24': 12900,
        },
        PeriodEnd: {
            '1': 1735148146,
            '12': 1764092146,
            '24': 1795628146,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 800,
        Vendors: {
            Google: {
                Plans: {},
                CustomerID: 'cus_google_a6ZjmXhxsOSi-yE7rTcH',
            },
            Apple: {
                Plans: {},
                CustomerID: '',
            },
        },
    },
    {
        ID: '0',
        ParentMetaPlanID: '0',
        Type: 2,
        Name: 'passlifetime2024' as PLANS | ADDON_NAMES,
        Title: 'Pass + SimpleLogin Lifetime',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 8,
        Features: 0,
        State: 1,
        Pricing: {
            '12': 19900,
        },
        DefaultPricing: {
            '12': 19900,
        },
        PeriodEnd: {
            '12': 8589934592,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 12,
        Amount: 19900,
        Vendors: [],
    },
    {
        ID: 'l8vWAXHBQmv0u7OVtPbcqMa4iwQaBqowINSQjPrxAr-Da8fVPKUkUcqAq30_BCxj1X0nW70HQRmAa-rIvzmKUA==',
        ParentMetaPlanID: 'hUcV0_EeNwUmXA6EoyNrtO-ZTD8H8F6LvNaSjMaPxB5ecFkA7y-5kc3q38cGumJENGHjtSoUndkYFUx0_xlJeg==',
        Type: 1,
        Name: 'lumo2024' as PLANS | ADDON_NAMES,
        Title: 'Lumo Plus',
        MaxDomains: 0,
        MaxAddresses: 0,
        MaxCalendars: 0,
        MaxSpace: 0,
        MaxMembers: 0,
        MaxVPN: 0,
        MaxTier: 0,
        Services: 64,
        Features: 0,
        State: 1,
        Pricing: {},
        DefaultPricing: {},
        PeriodEnd: {
            '1': 1736074694,
            '12': 1764932294,
        },
        Currency: 'CHF',
        Quantity: 1,
        Offers: [],
        Cycle: 1,
        Amount: 0,
        Vendors: {},
    },
];

export const cachedPlansMap = getPlansMap(cachedPlans, 'USD');
