/**
 * THIS CODE IS AUTOGENERATED
 * using `yarn workspace @proton/metrics generate-metrics`
 *
 * For more information please consult the documentation
 * https://confluence.protontech.ch/pages/viewpage.action?pageId=121927830
 */
import Counter from './lib/Counter';
import Histogram from './lib/Histogram';
import MetricsBase from './lib/MetricsBase';
import type IMetricsRequestService from './lib/types/IMetricsRequestService';
import type { HttpsProtonMeDesktopInboxHeartbeatTotalV1SchemaJson } from './types/desktop_inbox_heartbeat_total_v1.schema';
import type { HttpsProtonMeDocsAbortedSquashesTotalV1SchemaJson } from './types/docs_aborted_squashes_total_v1.schema';
import type { HttpsProtonMeDocsAlertModalTotalV1SchemaJson } from './types/docs_alert_modal_total_v1.schema';
import type { HttpsProtonMeDocsCommentsDownloadErrorTotalV1SchemaJson } from './types/docs_comments_download_error_total_v1.schema';
import type { HttpsProtonMeDocsCommentsErrorTotalV1SchemaJson } from './types/docs_comments_error_total_v1.schema';
import type { HttpsProtonMeDocsCommentsTotalV1SchemaJson } from './types/docs_comments_total_v1.schema';
import type { HttpsProtonMeDocsCommitIdOutOfSyncTotalV1SchemaJson } from './types/docs_commit_id_out_of_sync_total_v1.schema';
import type { HttpsProtonMeDocsCommitSignatureErrorTotalV1SchemaJson } from './types/docs_commit_signature_error_total_v1.schema';
import type { HttpsProtonMeDocsDocumentUpdatesAckErrorTotalV1SchemaJson } from './types/docs_document_updates_ack_error_total_v1.schema';
import type { HttpsProtonMeDocsDocumentUpdatesDecryptionErrorTotalV1SchemaJson } from './types/docs_document_updates_decryption_error_total_v1.schema';
import type { HttpsProtonMeDocsDocumentUpdatesLoadErrorTotalV1SchemaJson } from './types/docs_document_updates_load_error_total_v1.schema';
import type { HttpsProtonMeDocsDocumentUpdatesSaveErrorTotalV1SchemaJson } from './types/docs_document_updates_save_error_total_v1.schema';
import type { HttpsProtonMeDocsDocumentUpdatesTotalV1SchemaJson } from './types/docs_document_updates_total_v1.schema';
import type { HttpsProtonMeDocsFailedWebsocketConnectionsTotalV1SchemaJson } from './types/docs_failed_websocket_connections_total_v1.schema';
import type { HttpsProtonMeDocsOpenDocumentsHeartbeatTotalV2SchemaJson } from './types/docs_open_documents_heartbeat_total_v2.schema';
import type { HttpsProtonMeDocsPublicSharingCustomPasswordSuccessRateTotalV1SchemaJson } from './types/docs_public_sharing_custom_password_success_rate_total_v1.schema';
import type { HttpsProtonMeDocsReadonlyModeDocumentsTotalV1SchemaJson } from './types/docs_readonly_mode_documents_total_v1.schema';
import type { HttpsProtonMeDocsRealtimeDisconnectErrorTotalV1SchemaJson } from './types/docs_realtime_disconnect_error_total_v1.schema';
import type { HttpsProtonMeDocsRealtimeEditLatencyHistogramV1SchemaJson } from './types/docs_realtime_edit_latency_histogram_v1.schema';
import type { HttpsProtonMeDocsRealtimeEditTimeToAckHistogramV1SchemaJson } from './types/docs_realtime_edit_time_to_ack_histogram_v1.schema';
import type { HttpsProtonMeDocsSquashesLatencyHistogramV1SchemaJson } from './types/docs_squashes_latency_histogram_v1.schema';
import type { HttpsProtonMeDocsSquashesTotalV1SchemaJson } from './types/docs_squashes_total_v1.schema';
import type { HttpsProtonMeDocsSuggestionsCreatedTotalV1SchemaJson } from './types/docs_suggestions_created_total_v1.schema';
import type { HttpsProtonMeDocsSuggestionsResolvedTotalV1SchemaJson } from './types/docs_suggestions_resolved_total_v1.schema';
import type { HttpsProtonMeDocsTimeLoadDocumentHistogramV1SchemaJson } from './types/docs_time_load_document_histogram_v1.schema';
import type { HttpsProtonMeDocsUsersSuccessRateTotalV1SchemaJson } from './types/docs_users_success_rate_total_v1.schema';
import type { HttpsProtonMeDriveDownloadErroringUsersTotalV1SchemaJson } from './types/drive_download_erroring_users_total_v1.schema';
import type { HttpsProtonMeDriveDownloadErrorsTotalV2SchemaJson } from './types/drive_download_errors_total_v2.schema';
import type { HttpsProtonMeDriveDownloadSuccessRateTotalV1SchemaJson } from './types/drive_download_success_rate_total_v1.schema';
import type { HttpsProtonMeDriveFilePreviewErrorsTotalV1SchemaJson } from './types/drive_file_preview_errors_total_v1.schema';
import type { HttpsProtonMeDriveIntegrityBlockVerificationErrorsTotalV1SchemaJson } from './types/drive_integrity_block_verification_errors_total_v1.schema';
import type { HttpsProtonMeDriveIntegrityDecryptionErrorsTotalV1SchemaJson } from './types/drive_integrity_decryption_errors_total_v1.schema';
import type { HttpsProtonMeDriveIntegrityErroringUsersTotalV1SchemaJson } from './types/drive_integrity_erroring_users_total_v1.schema';
import type { HttpsProtonMeDriveIntegrityVerificationErrorsTotalV1SchemaJson } from './types/drive_integrity_verification_errors_total_v1.schema';
import type { HttpsProtonMeDriveSyncErroringUsersTotalV1SchemaJson } from './types/drive_sync_erroring_users_total_v1.schema';
import type { HttpsProtonMeDriveSyncErrorsTotalV1SchemaJson } from './types/drive_sync_errors_total_v1.schema';
import type { HttpsProtonMeDriveSyncEventTotalV2SchemaJson } from './types/drive_sync_event_total_v2.schema';
import type { HttpsProtonMeDriveSyncEventUnecessaryTotalV2SchemaJson } from './types/drive_sync_event_unecessary_total_v2.schema';
import type { HttpsProtonMeDriveSyncItemsTotalV1SchemaJson } from './types/drive_sync_items_total_v1.schema';
import type { HttpsProtonMeDriveSyncResyncItemsTotalV1SchemaJson } from './types/drive_sync_resync_items_total_v1.schema';
import type { HttpsProtonMeDriveSyncResyncSuccessTotalV1SchemaJson } from './types/drive_sync_resync_success_total_v1.schema';
import type { HttpsProtonMeDriveSyncResyncTotalV1SchemaJson } from './types/drive_sync_resync_total_v1.schema';
import type { HttpsProtonMeDriveUploadErroringUsersTotalV2SchemaJson } from './types/drive_upload_erroring_users_total_v2.schema';
import type { HttpsProtonMeDriveUploadErrorsFileSizeHistogramV1SchemaJson } from './types/drive_upload_errors_file_size_histogram_v1.schema';
import type { HttpsProtonMeDriveUploadErrorsTotalV2SchemaJson } from './types/drive_upload_errors_total_v2.schema';
import type { HttpsProtonMeDriveUploadErrorsTransferSizeHistogramV1SchemaJson } from './types/drive_upload_errors_transfer_size_histogram_v1.schema';
import type { HttpsProtonMeDriveUploadSuccessRateTotalV2SchemaJson } from './types/drive_upload_success_rate_total_v2.schema';
import type { HttpsProtonMeDriveUsersSuccessRateTotalV1SchemaJson } from './types/drive_users_success_rate_total_v1.schema';
import type { HttpsProtonMeDriveVolumeEventsSubscriptionsHistogramV1SchemaJson } from './types/drive_volume_events_subscriptions_histogram_v1.schema';
import type { CalendarEventDisplayTime } from './types/web_calendar_calendar_event_display_time_histogram_v1.schema';
import type { EventActionLatency } from './types/web_calendar_event_action_latency_histogram_v1.schema';
import type { EventRSVPResponseTime } from './types/web_calendar_event_rsvp_response_time_histogram_v1.schema';
import type { NewEventSetupTime } from './types/web_calendar_new_event_setup_time_histogram_v1.schema';
import type { CalendarPageTransitionTime } from './types/web_calendar_page_transition_time_histogram_v1.schema';
import type { WebCoreDeleteAccountTotal } from './types/web_core_delete_account_total_v1.schema';
import type { WebCoreLightLabellingImageProcessingTotal } from './types/web_core_lightLabelling_imageProcessing_total_v1.schema';
import type { WebCoreLightLabellingLogoRemovalTotal } from './types/web_core_lightLabelling_logoRemoval_total_v1.schema';
import type { WebCoreLightLabellingLogoUploadTotal } from './types/web_core_lightLabelling_logoUpload_total_v1.schema';
import type { WebCoreSessionRecoveryAbortTotal } from './types/web_core_session_recovery_abort_total_v1.schema';
import type { WebCoreSessionRecoveryCancellationModalLoadTotal } from './types/web_core_session_recovery_cancellation_modal_load_total_v1.schema';
import type { WebCoreSessionRecoveryConsumeTotal } from './types/web_core_session_recovery_consume_total_v1.schema';
import type { WebCoreSessionRecoveryInitiationModalLoadTotal } from './types/web_core_session_recovery_initiation_modal_load_total_v1.schema';
import type { WebCoreSessionRecoveryInitiationTotal } from './types/web_core_session_recovery_initiation_total_v1.schema';
import type { WebCoreSessionRecoveryPasswordResetAvailableAccountModalLoadTotal } from './types/web_core_session_recovery_password_reset_available_account_modal_load_total_v1.schema';
import type { WebCoreSessionRecoverySettingsUpdateTotal } from './types/web_core_session_recovery_settings_update_total_v1.schema';
import type { WebCoreSignupAccountStepAccountCreationTotal } from './types/web_core_signup_accountStep_accountCreation_total_v2.schema';
import type { WebCoreSignupBackButtonTotal } from './types/web_core_signup_backButton_total_v1.schema';
import type { WebCoreSignupCongratulationsStepDisplayNameChoiceTotal } from './types/web_core_signup_congratulationsStep_displayNameChoice_total_v2.schema';
import type { WebCoreSignupExploreStepLoginTotal } from './types/web_core_signup_exploreStep_login_total_v2.schema';
import type { WebCoreSignupLoadingStepAccountSetupTotal } from './types/web_core_signup_loadingStep_accountSetup_total_v2.schema';
import type { WebCoreSignupPageLoadTotal } from './types/web_core_signup_pageLoad_total_v1.schema';
import type { WebCoreSignupPaymentStepPaymentTotal } from './types/web_core_signup_paymentStep_payment_total_v2.schema';
import type { WebCoreSignupRecoveryStepSetRecoveryMethodTotal } from './types/web_core_signup_recoveryStep_setRecoveryMethod_total_v2.schema';
import type { WebCoreSignupReferralStepPlanSelectionTotal } from './types/web_core_signup_referralStep_planSelection_total_v2.schema';
import type { WebCoreSignupUpsellStepPlanSelectionTotal } from './types/web_core_signup_upsellStep_planSelection_total_v2.schema';
import type { WebCoreSignupVerificationStepVerificationTotal } from './types/web_core_signup_verificationStep_verification_total_v2.schema';
import type { WebCoreSingleSignupCompleteTotal } from './types/web_core_single_signup_complete_total_v1.schema';
import type { WebCoreSingleSignupFetchDependenciesTotal } from './types/web_core_single_signup_fetchDependencies_total_v1.schema';
import type { WebCoreSingleSignupPageLoadTotal } from './types/web_core_single_signup_pageLoad_total_v1.schema';
import type { WebCoreSingleSignupSetupTotal } from './types/web_core_single_signup_setup_total_v1.schema';
import type { WebCoreSingleSignupSignedInSessionTotal } from './types/web_core_single_signup_signedInSession_total_v1.schema';
import type { WebCoreSingleSignupSwitchSessionTotal } from './types/web_core_single_signup_switchSession_total_v1.schema';
import type { WebCoreSSORemoveDomainModalLoadTotal } from './types/web_core_sso_remove_domain_modal_load_total_v1.schema';
import type { WebCoreSSORemoveDomainTotal } from './types/web_core_sso_remove_domain_total_v1.schema';
import type { WebCoreSSOSamlInfoSectionLoadTotal } from './types/web_core_sso_saml_info_page_load_total_v1.schema';
import type { WebCoreSSOSamlSetupModalLoadTotal } from './types/web_core_sso_saml_setup_modal_load_total_v1.schema';
import type { WebCoreSSOSamlSetupTotal } from './types/web_core_sso_saml_setup_total_v1.schema';
import type { WebCoreSSOSamlUpdateInfoTotal } from './types/web_core_sso_saml_update_info_total_v1.schema';
import type { WebCoreSSOSetupDomainModalLoadTotal } from './types/web_core_sso_setup_domain_modal_load_total_v1.schema';
import type { WebCoreSSOSetupDomainTotal } from './types/web_core_sso_setup_domain_total_v1.schema';
import type { WebCoreVersionJsonFailedTotal } from './types/web_core_version_json_failed_total_v1.schema';
import type { WebCoreVpnSingleSignupFetchDependencies2Total } from './types/web_core_vpn_single_signup_fetchDependencies_2_total_v1.schema';
import type { WebCoreVpnSingleSignupFetchDependenciesTotal } from './types/web_core_vpn_single_signup_fetchDependencies_total_v1.schema';
import type { WebCoreVpnSingleSignupPageLoad2Total } from './types/web_core_vpn_single_signup_pageLoad_2_total_v1.schema';
import type { WebCoreVpnSingleSignupPageLoadTotal } from './types/web_core_vpn_single_signup_pageLoad_total_v1.schema';
import type { WebCoreVpnSingleSignupPasswordSelectionStep2Total } from './types/web_core_vpn_single_signup_passwordSelection_step_2_total_v1.schema';
import type { WebCoreVpnSingleSignupPasswordSelectionStepTotal } from './types/web_core_vpn_single_signup_passwordSelection_step_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1AccountCreation2Total } from './types/web_core_vpn_single_signup_step1_accountCreation_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1AccountCreationTotal } from './types/web_core_vpn_single_signup_step1_accountCreation_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1CurrencyChange2Total } from './types/web_core_vpn_single_signup_step1_currencyChange_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1CurrencyChangeTotal } from './types/web_core_vpn_single_signup_step1_currencyChange_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1CycleChange2Total } from './types/web_core_vpn_single_signup_step1_cycleChange_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1CycleChangeTotal } from './types/web_core_vpn_single_signup_step1_cycleChange_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1Interaction2Total } from './types/web_core_vpn_single_signup_step1_interaction_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1InteractionTotal } from './types/web_core_vpn_single_signup_step1_interaction_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1Payment2Total } from './types/web_core_vpn_single_signup_step1_payment_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep1PaymentTotal } from './types/web_core_vpn_single_signup_step1_payment_total_v1.schema';
import type { WebCoreVpnSingleSignupStep2Setup2Total } from './types/web_core_vpn_single_signup_step2_setup_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep2Setup3Total } from './types/web_core_vpn_single_signup_step2_setup_3_total_v1.schema';
import type { WebCoreVpnSingleSignupStep2SetupTotal } from './types/web_core_vpn_single_signup_step2_setup_total_v1.schema';
import type { WebCoreVpnSingleSignupStep3Complete2Total } from './types/web_core_vpn_single_signup_step3_complete_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep3CompleteTotal } from './types/web_core_vpn_single_signup_step3_complete_total_v1.schema';
import type { WebCoreVpnSingleSignupStep4OrgSetupTotal } from './types/web_core_vpn_single_signup_step4_orgSetup_total_v1.schema';
import type { WebCoreVpnSingleSignupStep4Setup2Total } from './types/web_core_vpn_single_signup_step4_setup_2_total_v1.schema';
import type { WebCoreVpnSingleSignupStep4SetupTotal } from './types/web_core_vpn_single_signup_step4_setup_total_v1.schema';
import type { HttpsProtonMeWebCoreWebvitalsTotalV1SchemaJson } from './types/web_core_webvitals_total_v1.schema';
import type { WebCryptoKeyTransparencyErrorsTotal } from './types/web_crypto_keytransparency_errors_total_v1.schema';
import type { HttpsProtonMeWebDriveDownloadMechanismSuccessRateTotalV1SchemaJson } from './types/web_drive_download_mechanism_success_rate_total_v1.schema';
import type { HttpsProtonMeWebDrivePerformanceAveragetimeperitemHistogramV2SchemaJson } from './types/web_drive_performance_averagetimeperitem_histogram_v2.schema';
import type { HttpsProtonMeWebDrivePerformanceClicktobootstrappedHistogramV1SchemaJson } from './types/web_drive_performance_clicktobootstrapped_histogram_v1.schema';
import type { HttpsProtonMeWebDrivePerformanceClicktofirstitemrenderedHistogramV2SchemaJson } from './types/web_drive_performance_clicktofirstitemrendered_histogram_v2.schema';
import type { HttpsProtonMeWebDrivePerformanceClicktofirstpagerenderedHistogramV2SchemaJson } from './types/web_drive_performance_clicktofirstpagerendered_histogram_v2.schema';
import type { HttpsProtonMeWebDrivePerformanceClicktolastitemrenderedHistogramV2SchemaJson } from './types/web_drive_performance_clicktolastitemrendered_histogram_v2.schema';
import type { HttpsProtonMeWebDrivePerformanceClicktonavrenderedHistogramV1SchemaJson } from './types/web_drive_performance_clicktonavrendered_histogram_v1.schema';
import type { HttpsProtonMeWebDrivePerformanceDomcontentloadedHistogramV1SchemaJson } from './types/web_drive_performance_domcontentloaded_histogram_v1.schema';
import type { HttpsProtonMeWebDrivePerformanceLoadHistogramV1SchemaJson } from './types/web_drive_performance_load_histogram_v1.schema';
import type { HttpsProtonMeWebDrivePublicShareLoadErrorTotalV1SchemaJson } from './types/web_drive_public_share_load_error_total_v1.schema';
import type { HttpsProtonMeWebDrivePublicShareLoadSuccessTotalV1SchemaJson } from './types/web_drive_public_share_load_success_total_v1.schema';
import type { EmailContentRenderTime } from './types/web_mail_performance_email_content_render_time_histogram_v1.schema';
import type { EmailContentRenderTimeSeconds } from './types/web_mail_performance_email_content_render_time_second_histogram_v1.schema';
import type { EmailListDisplayTime } from './types/web_mail_performance_email_list_display_time_histogram_v1.schema';
import type { PageTransitionTime } from './types/web_mail_performance_page_transition_time_histogram_v1.schema';
import type { WebPaymentsSubscriptionStepsTotal } from './types/web_payments_subscription_steps_total_v1.schema';
import type { WebPaymentsSubscriptionTotal } from './types/web_payments_subscription_total_v1.schema';

class Metrics extends MetricsBase {
    public desktop_inbox_heartbeat_total: Counter<HttpsProtonMeDesktopInboxHeartbeatTotalV1SchemaJson>;

    public docs_aborted_squashes_total: Counter<HttpsProtonMeDocsAbortedSquashesTotalV1SchemaJson>;

    public docs_alert_modal_total: Counter<HttpsProtonMeDocsAlertModalTotalV1SchemaJson>;

    public docs_comments_download_error_total: Counter<HttpsProtonMeDocsCommentsDownloadErrorTotalV1SchemaJson>;

    public docs_comments_error_total: Counter<HttpsProtonMeDocsCommentsErrorTotalV1SchemaJson>;

    public docs_comments_total: Counter<HttpsProtonMeDocsCommentsTotalV1SchemaJson>;

    public docs_commit_id_out_of_sync_total: Counter<HttpsProtonMeDocsCommitIdOutOfSyncTotalV1SchemaJson>;

    public docs_commit_signature_error_total: Counter<HttpsProtonMeDocsCommitSignatureErrorTotalV1SchemaJson>;

    public docs_document_updates_ack_error_total: Counter<HttpsProtonMeDocsDocumentUpdatesAckErrorTotalV1SchemaJson>;

    public docs_document_updates_decryption_error_total: Counter<HttpsProtonMeDocsDocumentUpdatesDecryptionErrorTotalV1SchemaJson>;

    public docs_document_updates_load_error_total: Counter<HttpsProtonMeDocsDocumentUpdatesLoadErrorTotalV1SchemaJson>;

    public docs_document_updates_save_error_total: Counter<HttpsProtonMeDocsDocumentUpdatesSaveErrorTotalV1SchemaJson>;

    public docs_document_updates_total: Counter<HttpsProtonMeDocsDocumentUpdatesTotalV1SchemaJson>;

    public docs_failed_websocket_connections_total: Counter<HttpsProtonMeDocsFailedWebsocketConnectionsTotalV1SchemaJson>;

    public docs_open_documents_heartbeat_total: Counter<HttpsProtonMeDocsOpenDocumentsHeartbeatTotalV2SchemaJson>;

    public docs_public_sharing_custom_password_success_rate_total: Counter<HttpsProtonMeDocsPublicSharingCustomPasswordSuccessRateTotalV1SchemaJson>;

    public docs_readonly_mode_documents_total: Counter<HttpsProtonMeDocsReadonlyModeDocumentsTotalV1SchemaJson>;

    public docs_realtime_disconnect_error_total: Counter<HttpsProtonMeDocsRealtimeDisconnectErrorTotalV1SchemaJson>;

    public docs_realtime_edit_latency_histogram: Histogram<HttpsProtonMeDocsRealtimeEditLatencyHistogramV1SchemaJson>;

    public docs_realtime_edit_time_to_ack_histogram: Histogram<HttpsProtonMeDocsRealtimeEditTimeToAckHistogramV1SchemaJson>;

    public docs_squashes_latency_histogram: Histogram<HttpsProtonMeDocsSquashesLatencyHistogramV1SchemaJson>;

    public docs_squashes_total: Counter<HttpsProtonMeDocsSquashesTotalV1SchemaJson>;

    public docs_suggestions_created_total: Counter<HttpsProtonMeDocsSuggestionsCreatedTotalV1SchemaJson>;

    public docs_suggestions_resolved_total: Counter<HttpsProtonMeDocsSuggestionsResolvedTotalV1SchemaJson>;

    public docs_time_load_document_histogram: Histogram<HttpsProtonMeDocsTimeLoadDocumentHistogramV1SchemaJson>;

    public docs_users_success_rate_total: Counter<HttpsProtonMeDocsUsersSuccessRateTotalV1SchemaJson>;

    public drive_download_erroring_users_total: Counter<HttpsProtonMeDriveDownloadErroringUsersTotalV1SchemaJson>;

    public drive_download_errors_total: Counter<HttpsProtonMeDriveDownloadErrorsTotalV2SchemaJson>;

    public drive_download_success_rate_total: Counter<HttpsProtonMeDriveDownloadSuccessRateTotalV1SchemaJson>;

    public drive_file_preview_errors_total: Counter<HttpsProtonMeDriveFilePreviewErrorsTotalV1SchemaJson>;

    public drive_integrity_block_verification_errors_total: Counter<HttpsProtonMeDriveIntegrityBlockVerificationErrorsTotalV1SchemaJson>;

    public drive_integrity_decryption_errors_total: Counter<HttpsProtonMeDriveIntegrityDecryptionErrorsTotalV1SchemaJson>;

    public drive_integrity_erroring_users_total: Counter<HttpsProtonMeDriveIntegrityErroringUsersTotalV1SchemaJson>;

    public drive_integrity_verification_errors_total: Counter<HttpsProtonMeDriveIntegrityVerificationErrorsTotalV1SchemaJson>;

    public drive_sync_erroring_users_total: Counter<HttpsProtonMeDriveSyncErroringUsersTotalV1SchemaJson>;

    public drive_sync_errors_total: Counter<HttpsProtonMeDriveSyncErrorsTotalV1SchemaJson>;

    public drive_sync_event_total: Counter<HttpsProtonMeDriveSyncEventTotalV2SchemaJson>;

    public drive_sync_event_unecessary_total: Counter<HttpsProtonMeDriveSyncEventUnecessaryTotalV2SchemaJson>;

    public drive_sync_items_total: Counter<HttpsProtonMeDriveSyncItemsTotalV1SchemaJson>;

    public drive_sync_resync_items_total: Counter<HttpsProtonMeDriveSyncResyncItemsTotalV1SchemaJson>;

    public drive_sync_resync_success_total: Counter<HttpsProtonMeDriveSyncResyncSuccessTotalV1SchemaJson>;

    public drive_sync_resync_total: Counter<HttpsProtonMeDriveSyncResyncTotalV1SchemaJson>;

    public drive_upload_erroring_users_total: Counter<HttpsProtonMeDriveUploadErroringUsersTotalV2SchemaJson>;

    public drive_upload_errors_file_size_histogram: Histogram<HttpsProtonMeDriveUploadErrorsFileSizeHistogramV1SchemaJson>;

    public drive_upload_errors_total: Counter<HttpsProtonMeDriveUploadErrorsTotalV2SchemaJson>;

    public drive_upload_errors_transfer_size_histogram: Histogram<HttpsProtonMeDriveUploadErrorsTransferSizeHistogramV1SchemaJson>;

    public drive_upload_success_rate_total: Counter<HttpsProtonMeDriveUploadSuccessRateTotalV2SchemaJson>;

    public drive_users_success_rate_total: Counter<HttpsProtonMeDriveUsersSuccessRateTotalV1SchemaJson>;

    public drive_volume_events_subscriptions_histogram: Histogram<HttpsProtonMeDriveVolumeEventsSubscriptionsHistogramV1SchemaJson>;

    public calendar_calendar_event_display_time_histogram: Histogram<CalendarEventDisplayTime>;

    public calendar_event_action_latency_histogram: Histogram<EventActionLatency>;

    public calendar_event_rsvp_response_time_histogram: Histogram<EventRSVPResponseTime>;

    public calendar_new_event_setup_time_histogram: Histogram<NewEventSetupTime>;

    public calendar_page_transition_time_histogram: Histogram<CalendarPageTransitionTime>;

    public core_delete_account_total: Counter<WebCoreDeleteAccountTotal>;

    public core_lightLabelling_imageProcessing_total: Counter<WebCoreLightLabellingImageProcessingTotal>;

    public core_lightLabelling_logoRemoval_total: Counter<WebCoreLightLabellingLogoRemovalTotal>;

    public core_lightLabelling_logoUpload_total: Counter<WebCoreLightLabellingLogoUploadTotal>;

    public core_session_recovery_abort_total: Counter<WebCoreSessionRecoveryAbortTotal>;

    public core_session_recovery_cancellation_modal_load_total: Counter<WebCoreSessionRecoveryCancellationModalLoadTotal>;

    public core_session_recovery_consume_total: Counter<WebCoreSessionRecoveryConsumeTotal>;

    public core_session_recovery_initiation_modal_load_total: Counter<WebCoreSessionRecoveryInitiationModalLoadTotal>;

    public core_session_recovery_initiation_total: Counter<WebCoreSessionRecoveryInitiationTotal>;

    public core_session_recovery_password_reset_available_account_modal_load_total: Counter<WebCoreSessionRecoveryPasswordResetAvailableAccountModalLoadTotal>;

    public core_session_recovery_settings_update_total: Counter<WebCoreSessionRecoverySettingsUpdateTotal>;

    public core_signup_accountStep_accountCreation_total: Counter<WebCoreSignupAccountStepAccountCreationTotal>;

    public core_signup_backButton_total: Counter<WebCoreSignupBackButtonTotal>;

    public core_signup_congratulationsStep_displayNameChoice_total: Counter<WebCoreSignupCongratulationsStepDisplayNameChoiceTotal>;

    public core_signup_exploreStep_login_total: Counter<WebCoreSignupExploreStepLoginTotal>;

    public core_signup_loadingStep_accountSetup_total: Counter<WebCoreSignupLoadingStepAccountSetupTotal>;

    public core_signup_pageLoad_total: Counter<WebCoreSignupPageLoadTotal>;

    public core_signup_paymentStep_payment_total: Counter<WebCoreSignupPaymentStepPaymentTotal>;

    public core_signup_recoveryStep_setRecoveryMethod_total: Counter<WebCoreSignupRecoveryStepSetRecoveryMethodTotal>;

    public core_signup_referralStep_planSelection_total: Counter<WebCoreSignupReferralStepPlanSelectionTotal>;

    public core_signup_upsellStep_planSelection_total: Counter<WebCoreSignupUpsellStepPlanSelectionTotal>;

    public core_signup_verificationStep_verification_total: Counter<WebCoreSignupVerificationStepVerificationTotal>;

    public core_single_signup_complete_total: Counter<WebCoreSingleSignupCompleteTotal>;

    public core_single_signup_fetchDependencies_total: Counter<WebCoreSingleSignupFetchDependenciesTotal>;

    public core_single_signup_pageLoad_total: Counter<WebCoreSingleSignupPageLoadTotal>;

    public core_single_signup_setup_total: Counter<WebCoreSingleSignupSetupTotal>;

    public core_single_signup_signedInSession_total: Counter<WebCoreSingleSignupSignedInSessionTotal>;

    public core_single_signup_switchSession_total: Counter<WebCoreSingleSignupSwitchSessionTotal>;

    public core_sso_remove_domain_modal_load_total: Counter<WebCoreSSORemoveDomainModalLoadTotal>;

    public core_sso_remove_domain_total: Counter<WebCoreSSORemoveDomainTotal>;

    public core_sso_saml_info_page_load_total: Counter<WebCoreSSOSamlInfoSectionLoadTotal>;

    public core_sso_saml_setup_modal_load_total: Counter<WebCoreSSOSamlSetupModalLoadTotal>;

    public core_sso_saml_setup_total: Counter<WebCoreSSOSamlSetupTotal>;

    public core_sso_saml_update_info_total: Counter<WebCoreSSOSamlUpdateInfoTotal>;

    public core_sso_setup_domain_modal_load_total: Counter<WebCoreSSOSetupDomainModalLoadTotal>;

    public core_sso_setup_domain_total: Counter<WebCoreSSOSetupDomainTotal>;

    public core_version_json_failed_total: Counter<WebCoreVersionJsonFailedTotal>;

    public core_vpn_single_signup_fetchDependencies_2_total: Counter<WebCoreVpnSingleSignupFetchDependencies2Total>;

    public core_vpn_single_signup_fetchDependencies_total: Counter<WebCoreVpnSingleSignupFetchDependenciesTotal>;

    public core_vpn_single_signup_pageLoad_2_total: Counter<WebCoreVpnSingleSignupPageLoad2Total>;

    public core_vpn_single_signup_pageLoad_total: Counter<WebCoreVpnSingleSignupPageLoadTotal>;

    public core_vpn_single_signup_passwordSelection_step_2_total: Counter<WebCoreVpnSingleSignupPasswordSelectionStep2Total>;

    public core_vpn_single_signup_passwordSelection_step_total: Counter<WebCoreVpnSingleSignupPasswordSelectionStepTotal>;

    public core_vpn_single_signup_step1_accountCreation_2_total: Counter<WebCoreVpnSingleSignupStep1AccountCreation2Total>;

    public core_vpn_single_signup_step1_accountCreation_total: Counter<WebCoreVpnSingleSignupStep1AccountCreationTotal>;

    public core_vpn_single_signup_step1_currencyChange_2_total: Counter<WebCoreVpnSingleSignupStep1CurrencyChange2Total>;

    public core_vpn_single_signup_step1_currencyChange_total: Counter<WebCoreVpnSingleSignupStep1CurrencyChangeTotal>;

    public core_vpn_single_signup_step1_cycleChange_2_total: Counter<WebCoreVpnSingleSignupStep1CycleChange2Total>;

    public core_vpn_single_signup_step1_cycleChange_total: Counter<WebCoreVpnSingleSignupStep1CycleChangeTotal>;

    public core_vpn_single_signup_step1_interaction_2_total: Counter<WebCoreVpnSingleSignupStep1Interaction2Total>;

    public core_vpn_single_signup_step1_interaction_total: Counter<WebCoreVpnSingleSignupStep1InteractionTotal>;

    public core_vpn_single_signup_step1_payment_2_total: Counter<WebCoreVpnSingleSignupStep1Payment2Total>;

    public core_vpn_single_signup_step1_payment_total: Counter<WebCoreVpnSingleSignupStep1PaymentTotal>;

    public core_vpn_single_signup_step2_setup_2_total: Counter<WebCoreVpnSingleSignupStep2Setup2Total>;

    public core_vpn_single_signup_step2_setup_3_total: Counter<WebCoreVpnSingleSignupStep2Setup3Total>;

    public core_vpn_single_signup_step2_setup_total: Counter<WebCoreVpnSingleSignupStep2SetupTotal>;

    public core_vpn_single_signup_step3_complete_2_total: Counter<WebCoreVpnSingleSignupStep3Complete2Total>;

    public core_vpn_single_signup_step3_complete_total: Counter<WebCoreVpnSingleSignupStep3CompleteTotal>;

    public core_vpn_single_signup_step4_orgSetup_total: Counter<WebCoreVpnSingleSignupStep4OrgSetupTotal>;

    public core_vpn_single_signup_step4_setup_2_total: Counter<WebCoreVpnSingleSignupStep4Setup2Total>;

    public core_vpn_single_signup_step4_setup_total: Counter<WebCoreVpnSingleSignupStep4SetupTotal>;

    public core_webvitals_total: Counter<HttpsProtonMeWebCoreWebvitalsTotalV1SchemaJson>;

    public crypto_keytransparency_errors_total: Counter<WebCryptoKeyTransparencyErrorsTotal>;

    public drive_download_mechanism_success_rate_total: Counter<HttpsProtonMeWebDriveDownloadMechanismSuccessRateTotalV1SchemaJson>;

    public drive_performance_averagetimeperitem_histogram: Histogram<HttpsProtonMeWebDrivePerformanceAveragetimeperitemHistogramV2SchemaJson>;

    public drive_performance_clicktobootstrapped_histogram: Histogram<HttpsProtonMeWebDrivePerformanceClicktobootstrappedHistogramV1SchemaJson>;

    public drive_performance_clicktofirstitemrendered_histogram: Histogram<HttpsProtonMeWebDrivePerformanceClicktofirstitemrenderedHistogramV2SchemaJson>;

    public drive_performance_clicktofirstpagerendered_histogram: Histogram<HttpsProtonMeWebDrivePerformanceClicktofirstpagerenderedHistogramV2SchemaJson>;

    public drive_performance_clicktolastitemrendered_histogram: Histogram<HttpsProtonMeWebDrivePerformanceClicktolastitemrenderedHistogramV2SchemaJson>;

    public drive_performance_clicktonavrendered_histogram: Histogram<HttpsProtonMeWebDrivePerformanceClicktonavrenderedHistogramV1SchemaJson>;

    public drive_performance_domcontentloaded_histogram: Histogram<HttpsProtonMeWebDrivePerformanceDomcontentloadedHistogramV1SchemaJson>;

    public drive_performance_load_histogram: Histogram<HttpsProtonMeWebDrivePerformanceLoadHistogramV1SchemaJson>;

    public drive_public_share_load_error_total: Counter<HttpsProtonMeWebDrivePublicShareLoadErrorTotalV1SchemaJson>;

    public drive_public_share_load_success_total: Counter<HttpsProtonMeWebDrivePublicShareLoadSuccessTotalV1SchemaJson>;

    public mail_performance_email_content_render_time_histogram: Histogram<EmailContentRenderTime>;

    public mail_performance_email_content_render_time_second_histogram: Histogram<EmailContentRenderTimeSeconds>;

    public mail_performance_email_list_display_time_histogram: Histogram<EmailListDisplayTime>;

    public mail_performance_page_transition_time_histogram: Histogram<PageTransitionTime>;

    public payments_subscription_steps_total: Counter<WebPaymentsSubscriptionStepsTotal>;

    public payments_subscription_total: Counter<WebPaymentsSubscriptionTotal>;

    constructor(requestService: IMetricsRequestService) {
        super(requestService);

        this.desktop_inbox_heartbeat_total = new Counter<HttpsProtonMeDesktopInboxHeartbeatTotalV1SchemaJson>(
            { name: 'desktop_inbox_heartbeat_total', version: 1 },
            this.requestService
        );

        this.docs_aborted_squashes_total = new Counter<HttpsProtonMeDocsAbortedSquashesTotalV1SchemaJson>(
            { name: 'docs_aborted_squashes_total', version: 1 },
            this.requestService
        );

        this.docs_alert_modal_total = new Counter<HttpsProtonMeDocsAlertModalTotalV1SchemaJson>(
            { name: 'docs_alert_modal_total', version: 1 },
            this.requestService
        );

        this.docs_comments_download_error_total = new Counter<HttpsProtonMeDocsCommentsDownloadErrorTotalV1SchemaJson>(
            { name: 'docs_comments_download_error_total', version: 1 },
            this.requestService
        );

        this.docs_comments_error_total = new Counter<HttpsProtonMeDocsCommentsErrorTotalV1SchemaJson>(
            { name: 'docs_comments_error_total', version: 1 },
            this.requestService
        );

        this.docs_comments_total = new Counter<HttpsProtonMeDocsCommentsTotalV1SchemaJson>(
            { name: 'docs_comments_total', version: 1 },
            this.requestService
        );

        this.docs_commit_id_out_of_sync_total = new Counter<HttpsProtonMeDocsCommitIdOutOfSyncTotalV1SchemaJson>(
            { name: 'docs_commit_id_out_of_sync_total', version: 1 },
            this.requestService
        );

        this.docs_commit_signature_error_total = new Counter<HttpsProtonMeDocsCommitSignatureErrorTotalV1SchemaJson>(
            { name: 'docs_commit_signature_error_total', version: 1 },
            this.requestService
        );

        this.docs_document_updates_ack_error_total =
            new Counter<HttpsProtonMeDocsDocumentUpdatesAckErrorTotalV1SchemaJson>(
                { name: 'docs_document_updates_ack_error_total', version: 1 },
                this.requestService
            );

        this.docs_document_updates_decryption_error_total =
            new Counter<HttpsProtonMeDocsDocumentUpdatesDecryptionErrorTotalV1SchemaJson>(
                { name: 'docs_document_updates_decryption_error_total', version: 1 },
                this.requestService
            );

        this.docs_document_updates_load_error_total =
            new Counter<HttpsProtonMeDocsDocumentUpdatesLoadErrorTotalV1SchemaJson>(
                { name: 'docs_document_updates_load_error_total', version: 1 },
                this.requestService
            );

        this.docs_document_updates_save_error_total =
            new Counter<HttpsProtonMeDocsDocumentUpdatesSaveErrorTotalV1SchemaJson>(
                { name: 'docs_document_updates_save_error_total', version: 1 },
                this.requestService
            );

        this.docs_document_updates_total = new Counter<HttpsProtonMeDocsDocumentUpdatesTotalV1SchemaJson>(
            { name: 'docs_document_updates_total', version: 1 },
            this.requestService
        );

        this.docs_failed_websocket_connections_total =
            new Counter<HttpsProtonMeDocsFailedWebsocketConnectionsTotalV1SchemaJson>(
                { name: 'docs_failed_websocket_connections_total', version: 1 },
                this.requestService
            );

        this.docs_open_documents_heartbeat_total =
            new Counter<HttpsProtonMeDocsOpenDocumentsHeartbeatTotalV2SchemaJson>(
                { name: 'docs_open_documents_heartbeat_total', version: 2 },
                this.requestService
            );

        this.docs_public_sharing_custom_password_success_rate_total =
            new Counter<HttpsProtonMeDocsPublicSharingCustomPasswordSuccessRateTotalV1SchemaJson>(
                { name: 'docs_public_sharing_custom_password_success_rate_total', version: 1 },
                this.requestService
            );

        this.docs_readonly_mode_documents_total = new Counter<HttpsProtonMeDocsReadonlyModeDocumentsTotalV1SchemaJson>(
            { name: 'docs_readonly_mode_documents_total', version: 1 },
            this.requestService
        );

        this.docs_realtime_disconnect_error_total =
            new Counter<HttpsProtonMeDocsRealtimeDisconnectErrorTotalV1SchemaJson>(
                { name: 'docs_realtime_disconnect_error_total', version: 1 },
                this.requestService
            );

        this.docs_realtime_edit_latency_histogram =
            new Histogram<HttpsProtonMeDocsRealtimeEditLatencyHistogramV1SchemaJson>(
                { name: 'docs_realtime_edit_latency_histogram', version: 1 },
                this.requestService
            );

        this.docs_realtime_edit_time_to_ack_histogram =
            new Histogram<HttpsProtonMeDocsRealtimeEditTimeToAckHistogramV1SchemaJson>(
                { name: 'docs_realtime_edit_time_to_ack_histogram', version: 1 },
                this.requestService
            );

        this.docs_squashes_latency_histogram = new Histogram<HttpsProtonMeDocsSquashesLatencyHistogramV1SchemaJson>(
            { name: 'docs_squashes_latency_histogram', version: 1 },
            this.requestService
        );

        this.docs_squashes_total = new Counter<HttpsProtonMeDocsSquashesTotalV1SchemaJson>(
            { name: 'docs_squashes_total', version: 1 },
            this.requestService
        );

        this.docs_suggestions_created_total = new Counter<HttpsProtonMeDocsSuggestionsCreatedTotalV1SchemaJson>(
            { name: 'docs_suggestions_created_total', version: 1 },
            this.requestService
        );

        this.docs_suggestions_resolved_total = new Counter<HttpsProtonMeDocsSuggestionsResolvedTotalV1SchemaJson>(
            { name: 'docs_suggestions_resolved_total', version: 1 },
            this.requestService
        );

        this.docs_time_load_document_histogram = new Histogram<HttpsProtonMeDocsTimeLoadDocumentHistogramV1SchemaJson>(
            { name: 'docs_time_load_document_histogram', version: 1 },
            this.requestService
        );

        this.docs_users_success_rate_total = new Counter<HttpsProtonMeDocsUsersSuccessRateTotalV1SchemaJson>(
            { name: 'docs_users_success_rate_total', version: 1 },
            this.requestService
        );

        this.drive_download_erroring_users_total =
            new Counter<HttpsProtonMeDriveDownloadErroringUsersTotalV1SchemaJson>(
                { name: 'drive_download_erroring_users_total', version: 1 },
                this.requestService
            );

        this.drive_download_errors_total = new Counter<HttpsProtonMeDriveDownloadErrorsTotalV2SchemaJson>(
            { name: 'drive_download_errors_total', version: 2 },
            this.requestService
        );

        this.drive_download_success_rate_total = new Counter<HttpsProtonMeDriveDownloadSuccessRateTotalV1SchemaJson>(
            { name: 'drive_download_success_rate_total', version: 1 },
            this.requestService
        );

        this.drive_file_preview_errors_total = new Counter<HttpsProtonMeDriveFilePreviewErrorsTotalV1SchemaJson>(
            { name: 'drive_file_preview_errors_total', version: 1 },
            this.requestService
        );

        this.drive_integrity_block_verification_errors_total =
            new Counter<HttpsProtonMeDriveIntegrityBlockVerificationErrorsTotalV1SchemaJson>(
                { name: 'drive_integrity_block_verification_errors_total', version: 1 },
                this.requestService
            );

        this.drive_integrity_decryption_errors_total =
            new Counter<HttpsProtonMeDriveIntegrityDecryptionErrorsTotalV1SchemaJson>(
                { name: 'drive_integrity_decryption_errors_total', version: 1 },
                this.requestService
            );

        this.drive_integrity_erroring_users_total =
            new Counter<HttpsProtonMeDriveIntegrityErroringUsersTotalV1SchemaJson>(
                { name: 'drive_integrity_erroring_users_total', version: 1 },
                this.requestService
            );

        this.drive_integrity_verification_errors_total =
            new Counter<HttpsProtonMeDriveIntegrityVerificationErrorsTotalV1SchemaJson>(
                { name: 'drive_integrity_verification_errors_total', version: 1 },
                this.requestService
            );

        this.drive_sync_erroring_users_total = new Counter<HttpsProtonMeDriveSyncErroringUsersTotalV1SchemaJson>(
            { name: 'drive_sync_erroring_users_total', version: 1 },
            this.requestService
        );

        this.drive_sync_errors_total = new Counter<HttpsProtonMeDriveSyncErrorsTotalV1SchemaJson>(
            { name: 'drive_sync_errors_total', version: 1 },
            this.requestService
        );

        this.drive_sync_event_total = new Counter<HttpsProtonMeDriveSyncEventTotalV2SchemaJson>(
            { name: 'drive_sync_event_total', version: 2 },
            this.requestService
        );

        this.drive_sync_event_unecessary_total = new Counter<HttpsProtonMeDriveSyncEventUnecessaryTotalV2SchemaJson>(
            { name: 'drive_sync_event_unecessary_total', version: 2 },
            this.requestService
        );

        this.drive_sync_items_total = new Counter<HttpsProtonMeDriveSyncItemsTotalV1SchemaJson>(
            { name: 'drive_sync_items_total', version: 1 },
            this.requestService
        );

        this.drive_sync_resync_items_total = new Counter<HttpsProtonMeDriveSyncResyncItemsTotalV1SchemaJson>(
            { name: 'drive_sync_resync_items_total', version: 1 },
            this.requestService
        );

        this.drive_sync_resync_success_total = new Counter<HttpsProtonMeDriveSyncResyncSuccessTotalV1SchemaJson>(
            { name: 'drive_sync_resync_success_total', version: 1 },
            this.requestService
        );

        this.drive_sync_resync_total = new Counter<HttpsProtonMeDriveSyncResyncTotalV1SchemaJson>(
            { name: 'drive_sync_resync_total', version: 1 },
            this.requestService
        );

        this.drive_upload_erroring_users_total = new Counter<HttpsProtonMeDriveUploadErroringUsersTotalV2SchemaJson>(
            { name: 'drive_upload_erroring_users_total', version: 2 },
            this.requestService
        );

        this.drive_upload_errors_file_size_histogram =
            new Histogram<HttpsProtonMeDriveUploadErrorsFileSizeHistogramV1SchemaJson>(
                { name: 'drive_upload_errors_file_size_histogram', version: 1 },
                this.requestService
            );

        this.drive_upload_errors_total = new Counter<HttpsProtonMeDriveUploadErrorsTotalV2SchemaJson>(
            { name: 'drive_upload_errors_total', version: 2 },
            this.requestService
        );

        this.drive_upload_errors_transfer_size_histogram =
            new Histogram<HttpsProtonMeDriveUploadErrorsTransferSizeHistogramV1SchemaJson>(
                { name: 'drive_upload_errors_transfer_size_histogram', version: 1 },
                this.requestService
            );

        this.drive_upload_success_rate_total = new Counter<HttpsProtonMeDriveUploadSuccessRateTotalV2SchemaJson>(
            { name: 'drive_upload_success_rate_total', version: 2 },
            this.requestService
        );

        this.drive_users_success_rate_total = new Counter<HttpsProtonMeDriveUsersSuccessRateTotalV1SchemaJson>(
            { name: 'drive_users_success_rate_total', version: 1 },
            this.requestService
        );

        this.drive_volume_events_subscriptions_histogram =
            new Histogram<HttpsProtonMeDriveVolumeEventsSubscriptionsHistogramV1SchemaJson>(
                { name: 'drive_volume_events_subscriptions_histogram', version: 1 },
                this.requestService
            );

        this.calendar_calendar_event_display_time_histogram = new Histogram<CalendarEventDisplayTime>(
            { name: 'web_calendar_calendar_event_display_time_histogram', version: 1 },
            this.requestService
        );

        this.calendar_event_action_latency_histogram = new Histogram<EventActionLatency>(
            { name: 'web_calendar_event_action_latency_histogram', version: 1 },
            this.requestService
        );

        this.calendar_event_rsvp_response_time_histogram = new Histogram<EventRSVPResponseTime>(
            { name: 'web_calendar_event_rsvp_response_time_histogram', version: 1 },
            this.requestService
        );

        this.calendar_new_event_setup_time_histogram = new Histogram<NewEventSetupTime>(
            { name: 'web_calendar_new_event_setup_time_histogram', version: 1 },
            this.requestService
        );

        this.calendar_page_transition_time_histogram = new Histogram<CalendarPageTransitionTime>(
            { name: 'web_calendar_page_transition_time_histogram', version: 1 },
            this.requestService
        );

        this.core_delete_account_total = new Counter<WebCoreDeleteAccountTotal>(
            { name: 'web_core_delete_account_total', version: 1 },
            this.requestService
        );

        this.core_lightLabelling_imageProcessing_total = new Counter<WebCoreLightLabellingImageProcessingTotal>(
            { name: 'web_core_lightLabelling_imageProcessing_total', version: 1 },
            this.requestService
        );

        this.core_lightLabelling_logoRemoval_total = new Counter<WebCoreLightLabellingLogoRemovalTotal>(
            { name: 'web_core_lightLabelling_logoRemoval_total', version: 1 },
            this.requestService
        );

        this.core_lightLabelling_logoUpload_total = new Counter<WebCoreLightLabellingLogoUploadTotal>(
            { name: 'web_core_lightLabelling_logoUpload_total', version: 1 },
            this.requestService
        );

        this.core_session_recovery_abort_total = new Counter<WebCoreSessionRecoveryAbortTotal>(
            { name: 'web_core_session_recovery_abort_total', version: 1 },
            this.requestService
        );

        this.core_session_recovery_cancellation_modal_load_total =
            new Counter<WebCoreSessionRecoveryCancellationModalLoadTotal>(
                { name: 'web_core_session_recovery_cancellation_modal_load_total', version: 1 },
                this.requestService
            );

        this.core_session_recovery_consume_total = new Counter<WebCoreSessionRecoveryConsumeTotal>(
            { name: 'web_core_session_recovery_consume_total', version: 1 },
            this.requestService
        );

        this.core_session_recovery_initiation_modal_load_total =
            new Counter<WebCoreSessionRecoveryInitiationModalLoadTotal>(
                { name: 'web_core_session_recovery_initiation_modal_load_total', version: 1 },
                this.requestService
            );

        this.core_session_recovery_initiation_total = new Counter<WebCoreSessionRecoveryInitiationTotal>(
            { name: 'web_core_session_recovery_initiation_total', version: 1 },
            this.requestService
        );

        this.core_session_recovery_password_reset_available_account_modal_load_total =
            new Counter<WebCoreSessionRecoveryPasswordResetAvailableAccountModalLoadTotal>(
                { name: 'web_core_session_recovery_password_reset_available_account_modal_load_total', version: 1 },
                this.requestService
            );

        this.core_session_recovery_settings_update_total = new Counter<WebCoreSessionRecoverySettingsUpdateTotal>(
            { name: 'web_core_session_recovery_settings_update_total', version: 1 },
            this.requestService
        );

        this.core_signup_accountStep_accountCreation_total = new Counter<WebCoreSignupAccountStepAccountCreationTotal>(
            { name: 'web_core_signup_accountStep_accountCreation_total', version: 2 },
            this.requestService
        );

        this.core_signup_backButton_total = new Counter<WebCoreSignupBackButtonTotal>(
            { name: 'web_core_signup_backButton_total', version: 1 },
            this.requestService
        );

        this.core_signup_congratulationsStep_displayNameChoice_total =
            new Counter<WebCoreSignupCongratulationsStepDisplayNameChoiceTotal>(
                { name: 'web_core_signup_congratulationsStep_displayNameChoice_total', version: 2 },
                this.requestService
            );

        this.core_signup_exploreStep_login_total = new Counter<WebCoreSignupExploreStepLoginTotal>(
            { name: 'web_core_signup_exploreStep_login_total', version: 2 },
            this.requestService
        );

        this.core_signup_loadingStep_accountSetup_total = new Counter<WebCoreSignupLoadingStepAccountSetupTotal>(
            { name: 'web_core_signup_loadingStep_accountSetup_total', version: 2 },
            this.requestService
        );

        this.core_signup_pageLoad_total = new Counter<WebCoreSignupPageLoadTotal>(
            { name: 'web_core_signup_pageLoad_total', version: 1 },
            this.requestService
        );

        this.core_signup_paymentStep_payment_total = new Counter<WebCoreSignupPaymentStepPaymentTotal>(
            { name: 'web_core_signup_paymentStep_payment_total', version: 2 },
            this.requestService
        );

        this.core_signup_recoveryStep_setRecoveryMethod_total =
            new Counter<WebCoreSignupRecoveryStepSetRecoveryMethodTotal>(
                { name: 'web_core_signup_recoveryStep_setRecoveryMethod_total', version: 2 },
                this.requestService
            );

        this.core_signup_referralStep_planSelection_total = new Counter<WebCoreSignupReferralStepPlanSelectionTotal>(
            { name: 'web_core_signup_referralStep_planSelection_total', version: 2 },
            this.requestService
        );

        this.core_signup_upsellStep_planSelection_total = new Counter<WebCoreSignupUpsellStepPlanSelectionTotal>(
            { name: 'web_core_signup_upsellStep_planSelection_total', version: 2 },
            this.requestService
        );

        this.core_signup_verificationStep_verification_total =
            new Counter<WebCoreSignupVerificationStepVerificationTotal>(
                { name: 'web_core_signup_verificationStep_verification_total', version: 2 },
                this.requestService
            );

        this.core_single_signup_complete_total = new Counter<WebCoreSingleSignupCompleteTotal>(
            { name: 'web_core_single_signup_complete_total', version: 1 },
            this.requestService
        );

        this.core_single_signup_fetchDependencies_total = new Counter<WebCoreSingleSignupFetchDependenciesTotal>(
            { name: 'web_core_single_signup_fetchDependencies_total', version: 1 },
            this.requestService
        );

        this.core_single_signup_pageLoad_total = new Counter<WebCoreSingleSignupPageLoadTotal>(
            { name: 'web_core_single_signup_pageLoad_total', version: 1 },
            this.requestService
        );

        this.core_single_signup_setup_total = new Counter<WebCoreSingleSignupSetupTotal>(
            { name: 'web_core_single_signup_setup_total', version: 1 },
            this.requestService
        );

        this.core_single_signup_signedInSession_total = new Counter<WebCoreSingleSignupSignedInSessionTotal>(
            { name: 'web_core_single_signup_signedInSession_total', version: 1 },
            this.requestService
        );

        this.core_single_signup_switchSession_total = new Counter<WebCoreSingleSignupSwitchSessionTotal>(
            { name: 'web_core_single_signup_switchSession_total', version: 1 },
            this.requestService
        );

        this.core_sso_remove_domain_modal_load_total = new Counter<WebCoreSSORemoveDomainModalLoadTotal>(
            { name: 'web_core_sso_remove_domain_modal_load_total', version: 1 },
            this.requestService
        );

        this.core_sso_remove_domain_total = new Counter<WebCoreSSORemoveDomainTotal>(
            { name: 'web_core_sso_remove_domain_total', version: 1 },
            this.requestService
        );

        this.core_sso_saml_info_page_load_total = new Counter<WebCoreSSOSamlInfoSectionLoadTotal>(
            { name: 'web_core_sso_saml_info_page_load_total', version: 1 },
            this.requestService
        );

        this.core_sso_saml_setup_modal_load_total = new Counter<WebCoreSSOSamlSetupModalLoadTotal>(
            { name: 'web_core_sso_saml_setup_modal_load_total', version: 1 },
            this.requestService
        );

        this.core_sso_saml_setup_total = new Counter<WebCoreSSOSamlSetupTotal>(
            { name: 'web_core_sso_saml_setup_total', version: 1 },
            this.requestService
        );

        this.core_sso_saml_update_info_total = new Counter<WebCoreSSOSamlUpdateInfoTotal>(
            { name: 'web_core_sso_saml_update_info_total', version: 1 },
            this.requestService
        );

        this.core_sso_setup_domain_modal_load_total = new Counter<WebCoreSSOSetupDomainModalLoadTotal>(
            { name: 'web_core_sso_setup_domain_modal_load_total', version: 1 },
            this.requestService
        );

        this.core_sso_setup_domain_total = new Counter<WebCoreSSOSetupDomainTotal>(
            { name: 'web_core_sso_setup_domain_total', version: 1 },
            this.requestService
        );

        this.core_version_json_failed_total = new Counter<WebCoreVersionJsonFailedTotal>(
            { name: 'web_core_version_json_failed_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_fetchDependencies_2_total =
            new Counter<WebCoreVpnSingleSignupFetchDependencies2Total>(
                { name: 'web_core_vpn_single_signup_fetchDependencies_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_fetchDependencies_total = new Counter<WebCoreVpnSingleSignupFetchDependenciesTotal>(
            { name: 'web_core_vpn_single_signup_fetchDependencies_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_pageLoad_2_total = new Counter<WebCoreVpnSingleSignupPageLoad2Total>(
            { name: 'web_core_vpn_single_signup_pageLoad_2_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_pageLoad_total = new Counter<WebCoreVpnSingleSignupPageLoadTotal>(
            { name: 'web_core_vpn_single_signup_pageLoad_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_passwordSelection_step_2_total =
            new Counter<WebCoreVpnSingleSignupPasswordSelectionStep2Total>(
                { name: 'web_core_vpn_single_signup_passwordSelection_step_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_passwordSelection_step_total =
            new Counter<WebCoreVpnSingleSignupPasswordSelectionStepTotal>(
                { name: 'web_core_vpn_single_signup_passwordSelection_step_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_accountCreation_2_total =
            new Counter<WebCoreVpnSingleSignupStep1AccountCreation2Total>(
                { name: 'web_core_vpn_single_signup_step1_accountCreation_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_accountCreation_total =
            new Counter<WebCoreVpnSingleSignupStep1AccountCreationTotal>(
                { name: 'web_core_vpn_single_signup_step1_accountCreation_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_currencyChange_2_total =
            new Counter<WebCoreVpnSingleSignupStep1CurrencyChange2Total>(
                { name: 'web_core_vpn_single_signup_step1_currencyChange_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_currencyChange_total =
            new Counter<WebCoreVpnSingleSignupStep1CurrencyChangeTotal>(
                { name: 'web_core_vpn_single_signup_step1_currencyChange_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_cycleChange_2_total =
            new Counter<WebCoreVpnSingleSignupStep1CycleChange2Total>(
                { name: 'web_core_vpn_single_signup_step1_cycleChange_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_cycleChange_total = new Counter<WebCoreVpnSingleSignupStep1CycleChangeTotal>(
            { name: 'web_core_vpn_single_signup_step1_cycleChange_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step1_interaction_2_total =
            new Counter<WebCoreVpnSingleSignupStep1Interaction2Total>(
                { name: 'web_core_vpn_single_signup_step1_interaction_2_total', version: 1 },
                this.requestService
            );

        this.core_vpn_single_signup_step1_interaction_total = new Counter<WebCoreVpnSingleSignupStep1InteractionTotal>(
            { name: 'web_core_vpn_single_signup_step1_interaction_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step1_payment_2_total = new Counter<WebCoreVpnSingleSignupStep1Payment2Total>(
            { name: 'web_core_vpn_single_signup_step1_payment_2_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step1_payment_total = new Counter<WebCoreVpnSingleSignupStep1PaymentTotal>(
            { name: 'web_core_vpn_single_signup_step1_payment_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step2_setup_2_total = new Counter<WebCoreVpnSingleSignupStep2Setup2Total>(
            { name: 'web_core_vpn_single_signup_step2_setup_2_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step2_setup_3_total = new Counter<WebCoreVpnSingleSignupStep2Setup3Total>(
            { name: 'web_core_vpn_single_signup_step2_setup_3_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step2_setup_total = new Counter<WebCoreVpnSingleSignupStep2SetupTotal>(
            { name: 'web_core_vpn_single_signup_step2_setup_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step3_complete_2_total = new Counter<WebCoreVpnSingleSignupStep3Complete2Total>(
            { name: 'web_core_vpn_single_signup_step3_complete_2_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step3_complete_total = new Counter<WebCoreVpnSingleSignupStep3CompleteTotal>(
            { name: 'web_core_vpn_single_signup_step3_complete_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step4_orgSetup_total = new Counter<WebCoreVpnSingleSignupStep4OrgSetupTotal>(
            { name: 'web_core_vpn_single_signup_step4_orgSetup_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step4_setup_2_total = new Counter<WebCoreVpnSingleSignupStep4Setup2Total>(
            { name: 'web_core_vpn_single_signup_step4_setup_2_total', version: 1 },
            this.requestService
        );

        this.core_vpn_single_signup_step4_setup_total = new Counter<WebCoreVpnSingleSignupStep4SetupTotal>(
            { name: 'web_core_vpn_single_signup_step4_setup_total', version: 1 },
            this.requestService
        );

        this.core_webvitals_total = new Counter<HttpsProtonMeWebCoreWebvitalsTotalV1SchemaJson>(
            { name: 'web_core_webvitals_total', version: 1 },
            this.requestService
        );

        this.crypto_keytransparency_errors_total = new Counter<WebCryptoKeyTransparencyErrorsTotal>(
            { name: 'web_crypto_keytransparency_errors_total', version: 1 },
            this.requestService
        );

        this.drive_download_mechanism_success_rate_total =
            new Counter<HttpsProtonMeWebDriveDownloadMechanismSuccessRateTotalV1SchemaJson>(
                { name: 'web_drive_download_mechanism_success_rate_total', version: 1 },
                this.requestService
            );

        this.drive_performance_averagetimeperitem_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceAveragetimeperitemHistogramV2SchemaJson>(
                { name: 'web_drive_performance_averagetimeperitem_histogram', version: 2 },
                this.requestService
            );

        this.drive_performance_clicktobootstrapped_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceClicktobootstrappedHistogramV1SchemaJson>(
                { name: 'web_drive_performance_clicktobootstrapped_histogram', version: 1 },
                this.requestService
            );

        this.drive_performance_clicktofirstitemrendered_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceClicktofirstitemrenderedHistogramV2SchemaJson>(
                { name: 'web_drive_performance_clicktofirstitemrendered_histogram', version: 2 },
                this.requestService
            );

        this.drive_performance_clicktofirstpagerendered_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceClicktofirstpagerenderedHistogramV2SchemaJson>(
                { name: 'web_drive_performance_clicktofirstpagerendered_histogram', version: 2 },
                this.requestService
            );

        this.drive_performance_clicktolastitemrendered_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceClicktolastitemrenderedHistogramV2SchemaJson>(
                { name: 'web_drive_performance_clicktolastitemrendered_histogram', version: 2 },
                this.requestService
            );

        this.drive_performance_clicktonavrendered_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceClicktonavrenderedHistogramV1SchemaJson>(
                { name: 'web_drive_performance_clicktonavrendered_histogram', version: 1 },
                this.requestService
            );

        this.drive_performance_domcontentloaded_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceDomcontentloadedHistogramV1SchemaJson>(
                { name: 'web_drive_performance_domcontentloaded_histogram', version: 1 },
                this.requestService
            );

        this.drive_performance_load_histogram =
            new Histogram<HttpsProtonMeWebDrivePerformanceLoadHistogramV1SchemaJson>(
                { name: 'web_drive_performance_load_histogram', version: 1 },
                this.requestService
            );

        this.drive_public_share_load_error_total =
            new Counter<HttpsProtonMeWebDrivePublicShareLoadErrorTotalV1SchemaJson>(
                { name: 'web_drive_public_share_load_error_total', version: 1 },
                this.requestService
            );

        this.drive_public_share_load_success_total =
            new Counter<HttpsProtonMeWebDrivePublicShareLoadSuccessTotalV1SchemaJson>(
                { name: 'web_drive_public_share_load_success_total', version: 1 },
                this.requestService
            );

        this.mail_performance_email_content_render_time_histogram = new Histogram<EmailContentRenderTime>(
            { name: 'web_mail_performance_email_content_render_time_histogram', version: 1 },
            this.requestService
        );

        this.mail_performance_email_content_render_time_second_histogram = new Histogram<EmailContentRenderTimeSeconds>(
            { name: 'web_mail_performance_email_content_render_time_second_histogram', version: 1 },
            this.requestService
        );

        this.mail_performance_email_list_display_time_histogram = new Histogram<EmailListDisplayTime>(
            { name: 'web_mail_performance_email_list_display_time_histogram', version: 1 },
            this.requestService
        );

        this.mail_performance_page_transition_time_histogram = new Histogram<PageTransitionTime>(
            { name: 'web_mail_performance_page_transition_time_histogram', version: 1 },
            this.requestService
        );

        this.payments_subscription_steps_total = new Counter<WebPaymentsSubscriptionStepsTotal>(
            { name: 'web_payments_subscription_steps_total', version: 1 },
            this.requestService
        );

        this.payments_subscription_total = new Counter<WebPaymentsSubscriptionTotal>(
            { name: 'web_payments_subscription_total', version: 1 },
            this.requestService
        );
    }
}

export default Metrics;
